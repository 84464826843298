import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import Hero from '../../components/Hero';
import About from '../../components/About';
import Experience from '../../components/Experience';
import Skills from '../../components/Skills';

const PageContainer = styled(motion.div)`
  min-height: 100vh;
`;

const Home: React.FC = () => {
  const pageVariants = {
    initial: { opacity: 0 },
    animate: { 
      opacity: 1,
      transition: { duration: 0.5 }
    },
    exit: { opacity: 0 }
  };

  return (
    <PageContainer
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <Hero />
      <About />
      <Experience />
      <Skills />
    </PageContainer>
  );
};

export default Home;
