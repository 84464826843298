import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const CoursesContainer = styled(motion.div)`
  min-height: 100vh;
  padding: 8rem 2rem 4rem;
`;

const CoursesContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const CoursesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 2rem 0;
`;

const CourseCard = styled(motion.div)`
  background: ${({ theme }) => theme.colors.card};
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const CourseHeader = styled.div`
  background: ${({ theme }) => theme.colors.primary};
  padding: 1.5rem;
  color: ${({ theme }) => theme.colors.text};
`;

const CourseTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
`;

const CourseCode = styled.span`
  font-family: ${({ theme }) => theme.fonts.code};
  font-size: 1rem;
  opacity: 0.8;
`;

const CourseContent = styled.div`
  padding: 1.5rem;
`;

const CourseDescription = styled.p`
  color: ${({ theme }) => theme.colors.textSecondary};
  margin-bottom: 1.5rem;
  line-height: 1.6;
`;

const CourseMeta = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 1rem;
  border-top: 1px solid ${({ theme }) => `${theme.colors.primary}30`};
`;

const CourseTermTag = styled.span`
  background: ${({ theme }) => theme.colors.accent};
  color: ${({ theme }) => theme.colors.text};
  padding: 0.25rem 0.75rem;
  border-radius: 20px;
  font-size: 0.875rem;
`;

const SectionTitle = styled.h2`
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.text};
  margin-bottom: 2rem;
  position: relative;
  display: inline-block;
  
  &::after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 0;
    width: 60%;
    height: 3px;
    background: ${({ theme }) => theme.colors.highlight};
    border-radius: 2px;
  }
`;

const SectionContainer = styled.div`
  margin-bottom: 4rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Courses: React.FC = () => {
  const dartmouthCourses = [
    {
      title: "Data Structures and Algorithms",
      code: "CS 31",
      description: "Fundamental data structures and algorithms, including sorting, searching, and graph algorithms.",
      term: "Fall 2023"
    },
    {
      title: "Software Development",
      code: "CS 50",
      description: "Modern software development practices, including version control, testing, and deployment.",
      term: "Winter 2024"
    },
    {
      title: "Machine Learning",
      code: "CS 74",
      description: "Introduction to machine learning concepts and applications in real-world scenarios.",
      term: "Spring 2024"
    }
  ];

  const otherCourses = [
    {
      title: "AWS Cloud Practitioner",
      code: "AWS",
      description: "Comprehensive understanding of AWS Cloud concepts, services, security, architecture, pricing, and support.",
      term: "2023"
    },
    {
      title: "Meta Front-End Developer Professional Certificate",
      code: "Meta",
      description: "Advanced front-end development skills including React, responsive design, and modern web development practices.",
      term: "2023"
    },
    {
      title: "Google Data Analytics Professional Certificate",
      code: "Google",
      description: "Data analytics fundamentals including SQL, R programming, data visualization, and statistical analysis.",
      term: "2023"
    }
  ];

  const containerVariants = {
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const cardVariants = {
    initial: { opacity: 0, y: 20 },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5
      }
    }
  };

  return (
    <CoursesContainer
      initial="initial"
      animate="animate"
      variants={containerVariants}
    >
      <CoursesContent>
        <SectionContainer>
          <SectionTitle>Dartmouth Courses</SectionTitle>
          <CoursesGrid>
            {dartmouthCourses.map((course, index) => (
              <CourseCard key={index} variants={cardVariants}>
                <CourseHeader>
                  <CourseTitle>{course.title}</CourseTitle>
                  <CourseCode>{course.code}</CourseCode>
                </CourseHeader>
                <CourseContent>
                  <CourseDescription>{course.description}</CourseDescription>
                  <CourseMeta>
                    <CourseTermTag>{course.term}</CourseTermTag>
                  </CourseMeta>
                </CourseContent>
              </CourseCard>
            ))}
          </CoursesGrid>
        </SectionContainer>

        <SectionContainer>
          <SectionTitle>Certifications & Other Courses</SectionTitle>
          <CoursesGrid>
            {otherCourses.map((course, index) => (
              <CourseCard key={index} variants={cardVariants}>
                <CourseHeader>
                  <CourseTitle>{course.title}</CourseTitle>
                  <CourseCode>{course.code}</CourseCode>
                </CourseHeader>
                <CourseContent>
                  <CourseDescription>{course.description}</CourseDescription>
                  <CourseMeta>
                    <CourseTermTag>{course.term}</CourseTermTag>
                  </CourseMeta>
                </CourseContent>
              </CourseCard>
            ))}
          </CoursesGrid>
        </SectionContainer>
      </CoursesContent>
    </CoursesContainer>
  );
};

export default Courses;
