import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const AboutSection = styled.section`
  padding: 6rem 2rem;
  background: ${({ theme }) => theme.colors.background};
  overflow: hidden;
  width: 100%;
`;

const AboutContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  align-items: center;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-template-columns: 1fr;
    text-align: center;
    gap: 2rem;
    width: 100%;
    padding: 0 1rem;
  }
`;

const AboutContent = styled(motion.div)`
  order: 1;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    order: 2;
    width: 100%;
    padding: 0;
  }

  h2 {
    font-size: clamp(2rem, 4vw, 3rem);
    margin-bottom: 2rem;
    color: ${({ theme }) => theme.colors.text};
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: -10px;
      left: 0;
      width: 60px;
      height: 4px;
      background: ${({ theme }) => theme.colors.highlight};

      @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  p {
    font-size: clamp(1rem, 2vw, 1.1rem);
    line-height: 1.8;
    color: ${({ theme }) => theme.colors.textSecondary};
    margin-bottom: 2rem;
    max-width: 100%;
  }
`;

const ProfileImage = styled(motion.div)`
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  order: 2;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    order: 1;
    max-width: 300px;
    margin-bottom: 1rem;
    width: 100%;
  }

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    image-rendering: -webkit-optimize-contrast;
    transform: translateZ(0);
    backface-visibility: hidden;
    -webkit-font-smoothing: subpixel-antialiased;
  }

  &::before {
    content: '';
    position: absolute;
    top: 20px;
    left: 20px;
    right: -20px;
    bottom: -20px;
    border: 2px solid ${({ theme }) => theme.colors.highlight};
    border-radius: 20px;
    z-index: -1;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      top: 10px;
      left: 10px;
      right: -10px;
      bottom: -10px;
    }
  }
`;

const ViewProjectsButton = styled(motion.a)`
  display: inline-block;
  padding: 1rem 2rem;
  background: ${({ theme }) => theme.colors.highlight};
  color: ${({ theme }) => theme.colors.background} !important;
  border-radius: 30px;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
  margin-top: 1rem;

  &:hover {
    transform: translateY(-3px);
    color: ${({ theme }) => theme.colors.background} !important;
    opacity: 0.9;
  }
`;

const About: React.FC = () => {
  return (
    <AboutSection id="about">
      <AboutContainer>
        <AboutContent
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.3 }}
          variants={{
            hidden: { 
              opacity: 0,
              x: -50,
              width: "100%"
            },
            visible: {
              opacity: 1,
              x: 0,
              width: "100%",
              transition: {
                type: "spring",
                stiffness: 100,
                damping: 20,
                mass: 1
              }
            }
          }}
        >
          <h2>About Me</h2>
          <p>
            I am a passionate software engineer with a strong foundation in computer science 
            and engineering principles. Currently pursuing my degree at Dartmouth College, 
            I have a keen interest in AI, machine learning, and full-stack development. 
            I thrive on solving complex problems and creating innovative solutions that 
            make a real-world impact.
          </p>
          <ViewProjectsButton
            href="/projects"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            View My Projects
          </ViewProjectsButton>
        </AboutContent>
        <ProfileImage
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.3 }}
          variants={{
            hidden: { 
              opacity: 0,
              x: 50,
              width: "100%"
            },
            visible: {
              opacity: 1,
              x: 0,
              width: "100%",
              transition: {
                type: "spring",
                stiffness: 100,
                damping: 20,
                mass: 1
              }
            }
          }}
        >
          <img 
            src="/images/prof.jpg" 
            alt="Muhammad Moiz"
            loading="eager"
            decoding="async"
          />
        </ProfileImage>
      </AboutContainer>
    </AboutSection>
  );
};

export default About;
