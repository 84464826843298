import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const BlogPostContainer = styled.article`
  max-width: 800px;
  margin: 0 auto;
  padding: 4rem 2rem;
`;

const BlogHeader = styled.header`
  margin-bottom: 3rem;
  text-align: center;
`;

const Title = styled(motion.h1)`
  font-size: 3rem;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.text.primary};
`;

const Meta = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  color: ${({ theme }) => theme.text.secondary};
  margin-bottom: 2rem;

  span {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  i {
    font-size: 1rem;
  }
`;

const FeaturedImage = styled(motion.img)`
  width: 100%;
  height: auto;
  border-radius: 12px;
  margin-bottom: 2rem;
`;

const Content = styled(motion.div)`
  color: ${({ theme }) => theme.text.secondary};
  line-height: 1.8;
  font-size: 1.1rem;

  h2 {
    color: ${({ theme }) => theme.text.primary};
    font-size: 2rem;
    margin: 2rem 0 1rem;
  }

  p {
    margin-bottom: 1.5rem;
  }

  code {
    background: ${({ theme }) => theme.background.secondary};
    padding: 0.2rem 0.4rem;
    border-radius: 4px;
    font-family: ${({ theme }) => theme.fonts.code};
  }

  pre {
    background: ${({ theme }) => theme.background.secondary};
    padding: 1rem;
    border-radius: 8px;
    overflow-x: auto;
    margin: 1.5rem 0;

    code {
      background: none;
      padding: 0;
    }
  }
`;

interface BlogPostProps {
  title: string;
  date: string;
  author: string;
  content: React.ReactNode;
  featuredImage: string;
}

const BlogPost: React.FC<BlogPostProps> = ({
  title,
  date,
  author,
  content,
  featuredImage
}) => {
  return (
    <BlogPostContainer>
      <BlogHeader>
        <Title
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          {title}
        </Title>
        <Meta>
          <span>
            <i className="fas fa-user"></i>
            {author}
          </span>
          <span>
            <i className="fas fa-calendar-alt"></i>
            {date}
          </span>
        </Meta>
        <FeaturedImage
          src={featuredImage}
          alt={title}
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        />
      </BlogHeader>
      <Content
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        {content}
      </Content>
    </BlogPostContainer>
  );
};

export default BlogPost;
