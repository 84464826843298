import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import Projects from '../../components/Projects';

const PageContainer = styled(motion.div)`
  min-height: 100vh;
`;

const ProjectsPage: React.FC = () => {
  const pageVariants = {
    initial: { opacity: 0 },
    animate: { 
      opacity: 1,
      transition: { duration: 0.5 }
    },
    exit: { opacity: 0 }
  };

  return (
    <PageContainer
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <Projects />
    </PageContainer>
  );
};

export default ProjectsPage;
