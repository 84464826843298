import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const BlogSection = styled.section`
  padding: 6rem 2rem 4rem 2rem;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding-top: 8rem;
  }
`;

const Title = styled(motion.h2)`
  font-size: 2.5rem;
  margin-bottom: 1rem;
  text-align: center;
  color: ${({ theme }) => theme.text.primary};
`;

const Description = styled(motion.p)`
  text-align: center;
  margin-bottom: 3rem;
  color: ${({ theme }) => theme.text.secondary};
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

const BlogPosts = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
`;

const BlogCard = styled(motion.div)`
  background: ${({ theme }) => theme.background.secondary};
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const BlogImage = styled.div`
  width: 100%;
  height: 200px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }

  &:hover img {
    transform: scale(1.05);
  }
`;

const BlogContent = styled.div`
  padding: 1.5rem;
`;

const BlogTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  
  a {
    color: ${({ theme }) => theme.text.primary};
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: ${({ theme }) => theme.button.primary};
    }
  }
`;

const BlogMeta = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.text.secondary};
  font-size: 0.9rem;

  span {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  i {
    font-size: 1rem;
  }
`;

const BlogExcerpt = styled.p`
  color: ${({ theme }) => theme.text.secondary};
  margin-bottom: 1.5rem;
  line-height: 1.6;
`;

const ReadMoreButton = styled.a`
  display: inline-block;
  padding: 0.8rem 1.5rem;
  background: ${({ theme }) => theme.button.primary};
  color: ${({ theme }) => theme.text.button} !important;
  text-decoration: none;
  border-radius: 6px;
  transition: all 0.3s ease;
  font-weight: 500;

  &:hover {
    background: ${({ theme }) => theme.button.hover};
    color: ${({ theme }) => theme.text.button} !important;
    opacity: 0.9;
  }
`;

const Blog: React.FC = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5
      }
    }
  };

  const blogPosts = [
    {
      title: "Understanding TensorFlow for Beginners",
      excerpt: "An introductory guide to getting started with TensorFlow for machine learning projects.",
      image: "/images/post1-thumbnail.jpg",
      author: "Muhammad Moiz",
      date: "October 5, 2024",
      link: "/blog/tensorflow-for-beginners"
    },
    {
      title: "Building RESTful APIs with Flask",
      excerpt: "A step-by-step tutorial on creating RESTful APIs using Python's Flask framework.",
      image: "/images/post2-thumbnail.jpg",
      author: "Muhammad Moiz",
      date: "September 20, 2024",
      link: "/blog/restful-apis-with-flask"
    }
  ];

  return (
    <BlogSection id="blog">
      <Title
        initial={{ opacity: 0, y: -20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
      >
        Blog
      </Title>
      <Description
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
      >
        Welcome to my blog! Here you'll find articles about software engineering, AI, machine learning, and the latest in technology. Explore and enjoy!
      </Description>

      <BlogPosts
        variants={containerVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        {blogPosts.map((post, index) => (
          <BlogCard
            key={index}
            variants={itemVariants}
          >
            <BlogImage>
              <img src={post.image} alt={post.title} />
            </BlogImage>
            <BlogContent>
              <BlogTitle>
                <a href={post.link}>{post.title}</a>
              </BlogTitle>
              <BlogMeta>
                <span>
                  <i className="fas fa-user"></i>
                  {post.author}
                </span>
                <span>
                  <i className="fas fa-calendar-alt"></i>
                  {post.date}
                </span>
              </BlogMeta>
              <BlogExcerpt>{post.excerpt}</BlogExcerpt>
              <ReadMoreButton href={post.link}>Read More</ReadMoreButton>
            </BlogContent>
          </BlogCard>
        ))}
      </BlogPosts>
    </BlogSection>
  );
};

export default Blog;
