import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const SkillsSection = styled.section`
  padding: 6rem 0;
  background: ${({ theme }) => theme.background.primary};
  min-height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: 4rem 0;
  }
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: 0 1.25rem;
  }
`;

const Title = styled(motion.h2)`
  font-size: clamp(2rem, 5vw, 2.5rem);
  color: ${({ theme }) => theme.text.primary};
  margin-bottom: 1rem;
  text-align: center;
`;

const Description = styled(motion.p)`
  color: ${({ theme }) => theme.text.secondary};
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  font-size: clamp(1rem, 3vw, 1.1rem);
  line-height: 1.6;
`;

const SkillsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 3rem;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
    margin-top: 2rem;
  }
`;

const SkillCategory = styled(motion.div)`
  background: ${({ theme }) => theme.background.secondary};
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 16px;
  padding: 1.5rem;
  backdrop-filter: blur(10px);
  height: 100%;
  transition: all 0.3s ease;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    border-radius: 12px;
    padding: 1.25rem;
  }

  &:hover {
    transform: translateY(-5px);
    border-color: ${({ theme }) => `${theme.colors.accent}30`};
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
  }
`;

const CategoryTitle = styled.h3`
  font-size: clamp(1.25rem, 4vw, 1.5rem);
  color: ${({ theme }) => theme.text.primary};
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  white-space: normal;
  line-height: 1.4;

  i {
    color: ${({ theme }) => theme.colors.accent};
    font-size: clamp(1.25rem, 4vw, 1.5rem);
    flex-shrink: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    margin-bottom: 1rem;
    gap: 0.5rem;
  }
`;

const SkillsList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.75rem;
  width: 100%;
`;

const SkillItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: ${({ theme }) => theme.text.secondary};
  font-size: clamp(0.9rem, 3vw, 1rem);
  padding: 0.75rem 1rem;
  border-radius: 8px;
  background: ${({ theme }) => `${theme.background.primary}50`};
  transition: all 0.2s ease;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: 0.6rem 0.8rem;
    font-size: 0.9rem;
  }

  &:hover {
    background: ${({ theme }) => `${theme.background.primary}80`};
    color: ${({ theme }) => theme.text.primary};
    transform: translateX(5px);
  }

  i {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.accent};
    flex-shrink: 0;
  }

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const Skills: React.FC = () => {
  const skillCategories = [
    {
      title: "Areas of Expertise",
      icon: "bx bx-code-block",
      skills: [
        "Machine Learning",
        "Full-Stack Development",
        "Cloud Computing",
        "System Design",
        "UI/UX Design",
        "Data Analysis"
      ]
    },
    {
      title: "Programming Languages",
      icon: "bx bx-code-alt",
      skills: [
        "Python",
        "JavaScript",
        "TypeScript",
        "Java",
        "C++",
        "SQL"
      ]
    },
    {
      title: "Frameworks & Tools",
      icon: "bx bx-layer",
      skills: [
        "React.js",
        "Node.js",
        "TensorFlow",
        "PyTorch",
        "Docker",
        "AWS"
      ]
    }
  ];

  return (
    <SkillsSection id="skills">
      <Container>
        <Title
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
        >
          Technical Skills
        </Title>
        <Description
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          A comprehensive overview of my technical expertise and proficiencies
        </Description>
        <SkillsGrid>
          {skillCategories.map((category, index) => (
            <SkillCategory
              key={category.title}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <CategoryTitle>
                <i className={category.icon}></i>
                {category.title}
              </CategoryTitle>
              <SkillsList>
                {category.skills.map((skill) => (
                  <SkillItem key={skill}>
                    <i className="bx bx-check"></i>
                    <span>{skill}</span>
                  </SkillItem>
                ))}
              </SkillsList>
            </SkillCategory>
          ))}
        </SkillsGrid>
      </Container>
    </SkillsSection>
  );
};

export default Skills;
