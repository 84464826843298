import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const NavContainer = styled(motion.nav)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  background: ${({ theme }) => theme.colors.card}CC;
  z-index: 1000;
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    height: auto;
    bottom: 0;
    top: auto;
    background: ${({ theme }) => theme.colors.card}F2;
    box-shadow: 0 -2px 20px rgba(0, 0, 0, 0.1);
  }
`;

const NavContent = styled.div`
  max-width: ${({ theme }) => theme.breakpoints.desktop};
  margin: 0 auto;
  padding: 0 2rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 0.5rem;
    flex-direction: column;
    gap: 0.25rem;
  }
`;

const Logo = styled(Link)`
  font-size: 1.5rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.text};
  text-decoration: none;
  font-family: 'JetBrains Mono', monospace;
  
  &:hover {
    color: ${({ theme }) => theme.colors.accent};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: none;
  }
`;

const NavLinks = styled.div`
  display: flex;
  gap: 1.5rem;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
    justify-content: space-around;
    gap: 0;
    padding: 0.5rem 1rem;
  }
`;

const NavLink = styled(Link)<{ $isActive?: boolean }>`
  color: ${({ theme, $isActive }) => 
    $isActive ? theme.colors.accent : theme.colors.text};
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  font-family: 'JetBrains Mono', monospace;
  position: relative;
  padding: 0.5rem;
  white-space: nowrap;
  transition: all 0.3s ease;
  
  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: ${({ theme }) => theme.colors.accent};
    transform: scaleX(${({ $isActive }) => ($isActive ? 1 : 0)});
    transform-origin: left;
    transition: transform 0.3s ease;
  }

  &:hover::after {
    transform: scaleX(1);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 0.85rem;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;
    opacity: ${({ $isActive }) => ($isActive ? 1 : 0.7)};

    &::after {
      display: none;
    }

    &::before {
      content: '';
      display: block;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: ${({ theme, $isActive }) => 
        $isActive ? theme.colors.accent : 'transparent'};
      margin-bottom: 4px;
      transition: all 0.3s ease;
    }

    &:hover {
      opacity: 1;
      transform: translateY(-2px);
      
      &::before {
        background: ${({ theme }) => theme.colors.accent};
      }
    }
  }
`;

const Navbar: React.FC = () => {
  const location = useLocation();

  const navItems = [
    { path: "/", label: "Home" },
    { path: "/projects", label: "Projects" },
    { path: "/blog", label: "Blog" },
    { path: "/courses", label: "Courses" }
  ];

  return (
    <NavContainer
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <NavContent>
        <Logo to="/">MM</Logo>
        <NavLinks>
          {navItems.map(item => (
            <NavLink 
              key={item.path} 
              to={item.path} 
              $isActive={location.pathname === item.path}
            >
              {item.label}
            </NavLink>
          ))}
        </NavLinks>
      </NavContent>
    </NavContainer>
  );
};

export default Navbar;
