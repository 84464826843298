import React, { useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const HeroSection = styled.section`
  position: relative;
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 2rem 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    min-height: 100vh;
    padding: 6rem 0 2rem 0;
  }
`;

const HeroContent = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
  z-index: 1;
  padding: 0 2rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: 0 1.5rem;
    margin-top: -2rem;
  }
`;

const AnimationContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;

  canvas {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    position: fixed;
    height: 100vh;
  }
`;

const Title = styled(motion.h1)`
  font-size: clamp(2.5rem, 8vw, 5rem);
  margin-bottom: 1rem;
  font-family: 'JetBrains Mono', monospace;
  position: relative;
  background: linear-gradient(
    135deg,
    ${({ theme }) => theme.colors.accent} 0%,
    ${({ theme }) => theme.colors.highlight} 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;

  &::before {
    content: '>';
    position: absolute;
    left: -2rem;
    color: ${({ theme }) => theme.colors.accent};
    -webkit-text-fill-color: ${({ theme }) => theme.colors.accent};
  }

  &::after {
    content: '_';
    animation: blink 1s step-end infinite;
    -webkit-text-fill-color: ${({ theme }) => theme.colors.accent};
  }

  @keyframes blink {
    50% { opacity: 0; }
  }
`;

const Subtitle = styled(motion.h2)`
  font-size: clamp(1.5rem, 4vw, 2.5rem);
  color: ${({ theme }) => theme.colors.textSecondary};
  margin-bottom: 2rem;
  font-family: 'JetBrains Mono', monospace;
  
  span {
    color: ${({ theme }) => theme.colors.accent};
  }
`;

const Description = styled(motion.p)`
  font-size: clamp(1rem, 2vw, 1.25rem);
  max-width: 600px;
  margin-bottom: 1.5rem;
  color: ${({ theme }) => theme.colors.textSecondary};
  position: relative;
  padding-left: 1.5rem;

  &::before {
    content: '/>';
    position: absolute;
    left: 0;
    color: ${({ theme }) => theme.colors.accent}80;
    font-family: 'Fira Code', monospace;
  }
`;

const SocialLinks = styled(motion.div)`
  display: flex;
  gap: 1.5rem;
  margin-bottom: 2rem;
  font-size: 1.5rem;

  a {
    color: ${({ theme }) => theme.colors.textSecondary};
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    text-decoration: none;
    font-size: 1rem;
    font-family: 'JetBrains Mono', monospace;

    i {
      font-size: 1.3em;
    }

    &:hover {
      color: ${({ theme }) => theme.colors.accent};
      transform: translateY(-2px);
    }

    span {
      @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
        display: none;
      }
    }
  }
`;

const TechIcons = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-top: 2rem;
  font-size: 2rem;
  width: 100%;
  max-width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    gap: 1.2rem;
    font-size: 1.75rem;
    justify-content: center;
    margin: 2rem -0.5rem 0;
    width: calc(100% + 1rem);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    gap: 0.8rem;
    font-size: 1.5rem;
    margin: 1.5rem -0.25rem 0;
    width: calc(100% + 0.5rem);
  }

  i {
    color: ${({ theme }) => theme.colors.textSecondary};
    transition: all 0.3s ease;
    
    &:hover {
      color: ${({ theme }) => theme.colors.accent};
      transform: translateY(-3px);
    }
  }
`;

interface Particle {
  x: number;
  y: number;
  vx: number;
  vy: number;
  size: number;
  color: string;
}

const Hero: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const particlesRef = useRef<Particle[]>([]);
  const mouseRef = useRef({ x: 0, y: 0 });
  const animationFrameRef = useRef<number>();
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const createParticles = useCallback((width: number, height: number) => {
    const particles: Particle[] = [];
    const particleCount = Math.min(Math.floor((width * height) / 15000), 100);
    
    for (let i = 0; i < particleCount; i++) {
      particles.push({
        x: Math.random() * width,
        y: Math.random() * height,
        vx: (Math.random() - 0.5) * 0.5,
        vy: (Math.random() - 0.5) * 0.5,
        size: Math.random() * 2 + 1,
        color: `rgba(${Math.random() * 50 + 100}, ${Math.random() * 50 + 150}, ${
          Math.random() * 50 + 200
        }, ${Math.random() * 0.5 + 0.2})`,
      });
    }
    return particles;
  }, []);

  const initCanvas = useCallback(() => {
    const canvas = canvasRef.current;
    const container = containerRef.current;
    if (!canvas || !container) return;

    const { width, height } = container.getBoundingClientRect();
    canvas.width = width;
    canvas.height = height;

    particlesRef.current = createParticles(width, height);
  }, [createParticles]);

  const drawParticles = useCallback(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    if (!canvas || !ctx) return;

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const particles = particlesRef.current;
    const mouse = mouseRef.current;

    // Draw connections
    particles.forEach((particle, i) => {
      particles.forEach((particle2, j) => {
        if (i === j) return;
        
        const dx = particle.x - particle2.x;
        const dy = particle.y - particle2.y;
        const distance = Math.sqrt(dx * dx + dy * dy);
        
        if (distance < 100) {
          ctx.beginPath();
          ctx.strokeStyle = `rgba(150, 200, 255, ${0.2 * (1 - distance / 100)})`;
          ctx.lineWidth = 0.5;
          ctx.moveTo(particle.x, particle.y);
          ctx.lineTo(particle2.x, particle2.y);
          ctx.stroke();
        }
      });
    });

    // Update and draw particles
    particles.forEach((particle) => {
      // Mouse interaction
      const dx = mouse.x - particle.x;
      const dy = mouse.y - particle.y;
      const distance = Math.sqrt(dx * dx + dy * dy);
      
      if (distance < 100) {
        const force = (100 - distance) * 0.0005;
        particle.vx -= dx * force;
        particle.vy -= dy * force;
      }

      // Update position
      particle.x += particle.vx;
      particle.y += particle.vy;

      // Bounce off walls
      if (particle.x < 0 || particle.x > canvas.width) particle.vx *= -1;
      if (particle.y < 0 || particle.y > canvas.height) particle.vy *= -1;

      // Apply friction
      particle.vx *= 0.99;
      particle.vy *= 0.99;

      // Draw particle
      ctx.beginPath();
      ctx.arc(particle.x, particle.y, particle.size, 0, Math.PI * 2);
      ctx.fillStyle = particle.color;
      ctx.fill();
    });

    animationFrameRef.current = requestAnimationFrame(drawParticles);
  }, []);

  const handleResize = useCallback(() => {
    const canvas = canvasRef.current;
    const container = containerRef.current;
    if (!canvas || !container) return;

    const { width, height } = container.getBoundingClientRect();
    canvas.width = width;
    canvas.height = height;

    particlesRef.current = createParticles(width, height);
  }, [createParticles]);

  const handleMouseMove = useCallback((e: MouseEvent | TouchEvent) => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const rect = canvas.getBoundingClientRect();
    
    if ('touches' in e) {
      mouseRef.current = {
        x: e.touches[0].clientX - rect.left,
        y: e.touches[0].clientY - rect.top,
      };
    } else {
      mouseRef.current = {
        x: e.clientX - rect.left,
        y: e.clientY - rect.top,
      };
    }
  }, []);

  useEffect(() => {
    initCanvas();
    drawParticles();

    window.addEventListener('resize', handleResize);
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('touchmove', handleMouseMove);

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('touchmove', handleMouseMove);
    };
  }, [initCanvas, drawParticles, handleResize, handleMouseMove]);

  return (
    <HeroSection ref={ref}>
      <AnimationContainer ref={containerRef}>
        <canvas ref={canvasRef} style={{ width: '100%', height: '100%' }} />
      </AnimationContainer>
      <HeroContent
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
      >
        <Title
          variants={{
            hidden: { opacity: 0, y: 20 },
            visible: {
              opacity: 1,
              y: 0,
              transition: {
                duration: 0.4,
                ease: [0.25, 0.1, 0.25, 1]
              }
            }
          }}
        >
          Muhammad Moiz
        </Title>
        <Subtitle
          variants={{
            hidden: { opacity: 0, y: 20 },
            visible: {
              opacity: 1,
              y: 0,
              transition: {
                duration: 0.4,
                ease: [0.25, 0.1, 0.25, 1]
              }
            }
          }}
        >
          <span>const</span> role = "<span>Software Engineer</span>";
        </Subtitle>
        <Description
          variants={{
            hidden: { opacity: 0, y: 20 },
            visible: {
              opacity: 1,
              y: 0,
              transition: {
                duration: 0.4,
                ease: [0.25, 0.1, 0.25, 1]
              }
            }
          }}
        >
          Transforming ideas into elegant code. Specializing in full-stack development
          and cloud architecture.
        </Description>
        <SocialLinks
          variants={{
            hidden: { opacity: 0, y: 20 },
            visible: {
              opacity: 1,
              y: 0,
              transition: {
                duration: 0.4,
                ease: [0.25, 0.1, 0.25, 1]
              }
            }
          }}
        >
          <a href="https://www.linkedin.com/in/moizofficial/" target="_blank" rel="noopener noreferrer">
            <i className="devicon-linkedin-plain"></i>
            <span>LinkedIn</span>
          </a>
          <a href="https://github.com/MuhammadMoiz20" target="_blank" rel="noopener noreferrer">
            <i className="devicon-github-original"></i>
            <span>GitHub</span>
          </a>
          <a href="mailto:muhammad.moiz.26@dartmouth.edu">
            <i className='bx bx-envelope'></i>
            <span>Email</span>
          </a>
        </SocialLinks>
        <TechIcons
          variants={{
            hidden: { opacity: 0, y: 20 },
            visible: {
              opacity: 1,
              y: 0,
              transition: {
                duration: 0.4,
                ease: [0.25, 0.1, 0.25, 1]
              }
            }
          }}
        >
          <i className="devicon-react-original"></i>
          <i className="devicon-typescript-plain"></i>
          <i className="devicon-python-plain"></i>
          <i className="devicon-nodejs-plain"></i>
          <i className="devicon-amazonwebservices-original"></i>
          <i className="devicon-docker-plain"></i>
        </TechIcons>
      </HeroContent>
    </HeroSection>
  );
};

export default Hero;
