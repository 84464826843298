const theme = {
  colors: {
    primary: '#1E1E2E',  // Dark editor-like background
    secondary: '#2D2D44', // Slightly lighter editor background
    accent: '#61DAFB',   // React blue
    highlight: '#C792EA', // Purple for keywords
    background: '#13111B', // VSCode-like dark background
    text: '#E4F0FB',     // Light editor text
    textSecondary: '#A7B7C7',
    card: '#1A1B26',     // Tokyo Night inspired
    success: '#98C379',  // Git addition green
    error: '#E06C75'     // Git deletion red
  },
  text: {
    primary: '#E4F0FB',
    secondary: '#A7B7C7',
    button: '#FFFFFF'
  },
  background: {
    primary: '#13111B',
    secondary: '#1A1B26'
  },
  button: {
    primary: '#61DAFB',
    hover: '#4FA8C7'
  },
  fonts: {
    primary: "'JetBrains Mono', 'Fira Code', monospace",
    code: "'Fira Code', monospace"
  },
  breakpoints: {
    mobile: '320px',
    tablet: '768px',
    desktop: '1024px',
    wide: '1440px'
  },
  transitions: {
    standard: '0.3s cubic-bezier(0.4, 0, 0.2, 1)',
    slow: '0.5s cubic-bezier(0.4, 0, 0.2, 1)',
    fast: '0.2s cubic-bezier(0.4, 0, 0.2, 1)'
  },
  spacing: {
    xs: '0.25rem',
    sm: '0.5rem',
    md: '1rem',
    lg: '2rem',
    xl: '4rem'
  }
};

export default theme;
