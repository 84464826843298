import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const ProjectsSection = styled.section`
  padding: 6rem 2rem 4rem 2rem;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding-top: 8rem;
  }
`;

const Title = styled(motion.h2)`
  font-size: 3rem;
  margin-bottom: 1.2rem;
  text-align: center;
  color: ${({ theme }) => theme.text.primary};
  font-weight: 700;
  letter-spacing: -0.03em;
  background: linear-gradient(
    135deg,
    ${({ theme }) => theme.text.primary} 0%,
    ${({ theme }) => theme.text.secondary} 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Description = styled(motion.p)`
  text-align: center;
  margin-bottom: 3.5rem;
  color: ${({ theme }) => theme.text.secondary};
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.2rem;
  line-height: 1.8;
`;

const ProjectsGrid = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  gap: 2.5rem;
  margin-top: 3rem;
`;

const ProjectCard = styled(motion.div)`
  background: ${({ theme }) => theme.background.secondary};
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(10px);

  &:hover {
    transform: translateY(-8px);
    box-shadow: 0 12px 40px rgba(0, 0, 0, 0.3);
    border-color: rgba(255, 255, 255, 0.1);
  }
`;

const ProjectImage = styled.div`
  width: 100%;
  height: 240px;
  overflow: hidden;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      180deg,
      transparent 0%,
      rgba(15, 23, 42, 0.8) 100%
    );
    z-index: 1;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  }

  ${ProjectCard}:hover & img {
    transform: scale(1.08);
  }
`;

const ProjectInfo = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  z-index: 2;
`;

const ProjectTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.text.primary};
  line-height: 1.4;
  letter-spacing: -0.02em;
`;

const ProjectDescription = styled.p`
  color: ${({ theme }) => theme.text.secondary};
  margin-bottom: 2rem;
  line-height: 1.7;
  font-size: 1.05rem;
  flex-grow: 1;
`;

const ProjectLink = styled.a`
  display: inline-flex;
  align-items: center;
  padding: 1rem 2rem;
  background: ${({ theme }) => theme.button.primary};
  color: ${({ theme }) => theme.text.button} !important;
  text-decoration: none;
  border-radius: 12px;
  font-weight: 500;
  letter-spacing: 0.3px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  overflow: hidden;
  width: 100%;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.1);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.2),
      transparent
    );
    transform: translateX(-100%);
    transition: transform 0.6s ease;
  }

  &:hover {
    background: ${({ theme }) => theme.button.hover};
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
  }

  &:hover::before {
    transform: translateX(100%);
  }

  i {
    margin-left: 0.75rem;
    font-size: 1.1rem;
    transition: transform 0.3s ease;
  }

  &:hover i {
    transform: translateX(4px);
  }
`;

const Projects: React.FC = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5
      }
    }
  };

  const projects = [
    {
      title: "Instagram Profile Archiver",
      description: "Developed a powerful Python tool that automates the archiving of public Instagram profiles, generating professional-quality PDF reports.",
      image: "/images/instagram-archiver-thumbnail.png",
      github: "https://github.com/MuhammadMoiz20/InstarchivePro"
    },
    {
      title: "YouTube Data Capture Tool",
      description: "Created an advanced Python application for capturing and organizing YouTube video and channel data using the YouTube Data API.",
      image: "/images/youtube-data-capture-thumbnail.png",
      github: "https://github.com/MuhammadMoiz20/YouTubeDataCapture"
    },
    {
      title: "GroupMe Shift Assistant",
      description: "A Python bot that automates shift management using GroupMe's API and Google Gemini AI.",
      image: "/images/groupme-shift-assistant-thumbnail.png",
      github: "https://github.com/MuhammadMoiz20/GroupMeShiftAssistant"
    }
  ];

  return (
    <ProjectsSection id="projects">
      <Title
        initial={{ opacity: 0, y: -20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
      >
        My Projects
      </Title>
      <Description
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
      >
        Below is a selection of my most significant projects, showcasing my expertise in software engineering, AI, machine learning, and full-stack development.
      </Description>

      <ProjectsGrid
        variants={containerVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        {projects.map((project, index) => (
          <ProjectCard
            key={index}
            variants={itemVariants}
          >
            <ProjectImage>
              <img src={project.image} alt={project.title} />
            </ProjectImage>
            <ProjectInfo>
              <ProjectTitle>{project.title}</ProjectTitle>
              <ProjectDescription>{project.description}</ProjectDescription>
              <ProjectLink href={project.github} target="_blank" rel="noopener noreferrer">
                View on GitHub <i className="fas fa-arrow-right"></i>
              </ProjectLink>
            </ProjectInfo>
          </ProjectCard>
        ))}
      </ProjectsGrid>
    </ProjectsSection>
  );
};

export default Projects;
