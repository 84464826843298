import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const ExperienceSection = styled.section`
  padding: 6rem 2rem;
  background: ${({ theme }) => `${theme.colors.primary}0a`};
`;

const ExperienceContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const SectionTitle = styled(motion.h2)`
  font-size: clamp(2rem, 4vw, 3rem);
  margin-bottom: 4rem;
  color: ${({ theme }) => theme.colors.text};
  position: relative;
  text-align: center;

  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 4px;
    background: ${({ theme }) => theme.colors.highlight};
  }
`;

const ExperienceGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
`;

const ExperienceCard = styled(motion.div)`
  background: ${({ theme }) => theme.colors.card};
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const CompanyName = styled.h3`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.text};
  margin-bottom: 0.5rem;
`;

const JobTitle = styled.h4`
  font-size: 1.25rem;
  color: ${({ theme }) => theme.colors.highlight};
  margin-bottom: 1rem;
`;

const Duration = styled.p`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.textSecondary};
  margin-bottom: 1.5rem;
  font-style: italic;
`;

const ResponsibilitiesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    color: ${({ theme }) => theme.colors.textSecondary};
    margin-bottom: 0.75rem;
    padding-left: 1.5rem;
    position: relative;
    line-height: 1.6;

    &::before {
      content: '▹';
      position: absolute;
      left: 0;
      color: ${({ theme }) => theme.colors.highlight};
    }
  }
`;

const Experience: React.FC = () => {
  const experiences = [
    {
      company: "Dartmouth Rauner Special Collections",
      title: "System Developer",
      duration: "April 2024 – Present",
      location: "Hanover, NH",
      responsibilities: [
        "Developed custom software solutions for archiving and digital preservation challenges.",
        "Automated YouTube data archival system using Python, Google API, and Selenium.",
        "Designed an Instagram archiving tool to scrape profile data and generate PDFs.",
        "Streamlined archival processes, significantly reducing manual workload."
      ]
    },
    {
      company: "Muff Garments",
      title: "Software Engineering Intern",
      duration: "March 2023 – May 2023",
      location: "Manchester, UK",
      responsibilities: [
        "Developed an AI-driven recommendation system for personalized shopping experiences.",
        "Optimized web interface, improving user engagement by 25%.",
        "Implemented analytics to track user behavior, refining marketing strategies."
      ]
    },
    {
      company: "Astraic Tech",
      title: "Senior Developer",
      duration: "May 2023 – August 2023",
      location: "Manchester, UK",
      responsibilities: [
        "Developed AI-powered web platform using TensorFlow and React.",
        "Implemented smart product recommendation engine, increasing sales by 15%.",
        "Created AI chatbot for real-time customer service."
      ]
    }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5
      }
    }
  };

  return (
    <ExperienceSection id="experience">
      <ExperienceContainer>
        <SectionTitle
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          Experience
        </SectionTitle>
        <ExperienceGrid
          as={motion.div}
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
        >
          {experiences.map((exp, index) => (
            <ExperienceCard key={index} variants={cardVariants}>
              <CompanyName>{exp.company}</CompanyName>
              <JobTitle>{exp.title}</JobTitle>
              <Duration>{exp.duration} | {exp.location}</Duration>
              <ResponsibilitiesList>
                {exp.responsibilities.map((resp, respIndex) => (
                  <li key={respIndex}>{resp}</li>
                ))}
              </ResponsibilitiesList>
            </ExperienceCard>
          ))}
        </ExperienceGrid>
      </ExperienceContainer>
    </ExperienceSection>
  );
};

export default Experience;
