import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { AnimatePresence } from 'framer-motion';
import GlobalStyle from './styles/GlobalStyle';
import theme from './styles/theme';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Projects from './pages/Projects';
import Blog from './pages/Blog';
import Courses from './pages/Courses';
import BlogPost from './components/BlogPost';

const tensorflowContent = (
  <>
    <h2>Introduction</h2>
    <p>
      TensorFlow is one of the most popular open-source libraries for machine learning and deep learning. 
      In this guide, we'll walk through the basics of getting started with TensorFlow and build our first neural network.
    </p>
    <h2>Setting Up Your Environment</h2>
    <p>
      First, let's set up our development environment. You'll need Python installed on your system. 
      Then, you can install TensorFlow using pip:
    </p>
    <pre>
      <code>
        pip install tensorflow
      </code>
    </pre>
    <h2>Your First TensorFlow Program</h2>
    <p>
      Let's create a simple program that demonstrates TensorFlow basics:
    </p>
    <pre>
      <code>
{`import tensorflow as tf

# Create a simple neural network
model = tf.keras.Sequential([
    tf.keras.layers.Dense(64, activation='relu', input_shape=(10,)),
    tf.keras.layers.Dense(32, activation='relu'),
    tf.keras.layers.Dense(1, activation='sigmoid')
])

# Compile the model
model.compile(optimizer='adam',
              loss='binary_crossentropy',
              metrics=['accuracy'])`}
      </code>
    </pre>
  </>
);

const flaskContent = (
  <>
    <h2>Introduction to Flask</h2>
    <p>
      Flask is a lightweight WSGI web application framework in Python. It's designed to be simple 
      and easy to extend, making it perfect for building RESTful APIs.
    </p>
    <h2>Setting Up Flask</h2>
    <p>
      To get started with Flask, you'll need to install it using pip:
    </p>
    <pre>
      <code>
        pip install flask
      </code>
    </pre>
    <h2>Creating Your First API</h2>
    <p>
      Here's a simple example of a Flask API:
    </p>
    <pre>
      <code>
{`from flask import Flask, jsonify

app = Flask(__name__)

@app.route('/api/hello', methods=['GET'])
def hello_world():
    return jsonify({
        'message': 'Hello, World!'
    })

if __name__ == '__main__':
    app.run(debug=True)`}
      </code>
    </pre>
  </>
);

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Router>
        <Navbar />
        <AnimatePresence mode="wait">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/courses" element={<Courses />} />
            <Route 
              path="/blog/tensorflow-for-beginners" 
              element={
                <BlogPost
                  title="Understanding TensorFlow for Beginners"
                  date="October 5, 2024"
                  author="Muhammad Moiz"
                  content={tensorflowContent}
                  featuredImage="/images/post1-thumbnail.jpg"
                />
              } 
            />
            <Route 
              path="/blog/restful-apis-with-flask" 
              element={
                <BlogPost
                  title="Building RESTful APIs with Flask"
                  date="September 20, 2024"
                  author="Muhammad Moiz"
                  content={flaskContent}
                  featuredImage="/images/post2-thumbnail.jpg"
                />
              } 
            />
          </Routes>
        </AnimatePresence>
      </Router>
    </ThemeProvider>
  );
};

export default App;
